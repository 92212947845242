import { AuthService } from "@adc/utilities/auth-service";
import { AuthStatus } from "@adc/utilities/iauthstatusresponse";
import axios from 'axios';
import * as data from "../../assets/header-data.json";
import * as memberData from "../../assets/memberInfoMockData.json";
import { TranslationDataController } from '../utilities/translation-data-controller';
import type { IAuthCoBrandResponse } from "@adc/utilities/iauthcobrandresponse";
import type { IAuthUserResponse } from "@adc/utilities/iauthuserresponse";
import type { ReactiveController, ReactiveControllerHost } from "lit";

export class HeaderDataController implements ReactiveController {
  authService = new AuthService();
  host: ReactiveControllerHost;
  public menuData: any;
  accountDetails: void | IAuthUserResponse;
  authStatus: AuthStatus = AuthStatus.GUEST;
  cobrandMembership = '';
  isDataFetched = false;

  constructor(host: ReactiveControllerHost) {
    (this.host = host).addController(this);
  }


  hostConnected() {
    this.fetchData();
    this.getAccountDetails().then(() => {
      this.host.requestUpdate();
    });
  }

  async fetchData() {
    const baseUrl = document.location.hostname;
    const protocol = document.location.protocol;
  
    if (baseUrl.includes("aa.com") || baseUrl.includes("american")) {
      try {
        const response = await TranslationDataController.getTranslationData('header', protocol);
        this.processData(response.data);
        this.host.requestUpdate();
      } catch (error) {
        console.error("Error fetching translation data:", error);
      }
    } else {
      this.processData(data);
    }
  }

  processData(jsonData) {
    this.menuData = JSON.parse(JSON.stringify(jsonData));
  }

  async getAccountDetails() {
    await this.getAuthStatus().then(() => {
      if (this.authStatus === AuthStatus.SECURED) {
        this.getMemberInformation().then(() => {
          this.getCoBrandMembership();
        });
      }
      this.isDataFetched = true;
    });
  }

  async getMemberInformation() {
    await this.authService.getMemberInformation().then(resp => {
      this.accountDetails = resp;
      this.host.requestUpdate();
    }).catch(err => {
      console.log("Loyalty api error", err);
    });
  }

  async getCoBrandMembership() {
    await axios.get<IAuthCoBrandResponse>('/loyalty/api/membership').then(resp => {
      if(this.accountDetails){
        this.accountDetails.cobrandMembership = resp.data.value;
      }
      this.host.requestUpdate();
    }).catch(err => {
      console.log("Loyalty api error", err);
    });
  }

  async getAuthStatus() {
    const urlParamValue = TranslationDataController.getURLParam();
    await this.authService.getAuthStatus(urlParamValue).then(resp => {
      this.authStatus = resp.status;
    }).catch(err => {
      const baseUrl = document.location.hostname;
      if (baseUrl.includes("aa.com") || baseUrl.includes("american")) {
        this.authStatus = AuthStatus.GUEST;
      } else {
        this.accountDetails = JSON.parse(JSON.stringify(memberData));
      }

      console.log('authStatus error, ', err);
    });
  }

}
