/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
 // eslint-disable-next-line @typescript-eslint/no-extraneous-class
 export class HeaderTranslations {
    /**
     * @ignore
     */
    public static DATA: any = {
      'en_US': {
        'Login': 'Log in',
        'Join': 'Join',
        'SearchAACom': 'Search AA.com®',
        'SubmitSearch' : 'Submit search',
        'SkipToGlobalNavigation': 'Skip to global navigation',
        'SkipToContent': 'Skip to content',
        'SkipToFooter': 'Skip to footer',
        'MobileNav': 'Menu link',
        'CloseMenu': 'Close menu',
        'LogInPopoverHeading': 'Have an AAdvantage® account?',
        'LogInPopoverBody': 'Log in here.',
        'TravelAlerts': 'Travel alerts'
      },
      'es': {
        'Login': 'Ingrese',
        'Join': '\u00DAnase',
        'SearchAACom': 'Buscar en aa.com',
        'SubmitSearch' : 'Enviar búsqueda',
        'SkipToGlobalNavigation': 'Pasar a la navegación',
        'SkipToContent': 'Pasar al contenido',
        'SkipToFooter': 'Pasar al pie de página',
        'MobileNav': 'Enlace del men\u00FA',
        'CloseMenu': 'Cerrar menú',
        'LogInPopoverHeading': '¿Tiene una cuenta AAdvantage®?',
        'LogInPopoverBody': 'Ingrese con su contraseña aquí.',
        'TravelAlerts': 'Alertas para su viaje'
      },
      'fr': {
        'Login': 'Connectez-vous',
        'Join': 'Rejoindre',
        'SkipToGlobalNavigation': 'Passer à la navigation générale',
        'SkipToContent': 'Passer au contenu',
        'SkipToFooter': 'Passer au bas de page',
        'MobileNav': 'Lien de menu',
        'CloseMenu': 'Fermer le menu',
        'LogInPopoverHeading': 'Avez-vous un compte AAdvantage®?',
        'LogInPopoverBody': 'Connectez-vous ici.',
        'TravelAlerts': 'Alerte voyage (en anglais)'
      },
      'pt': {
        'Login': 'Efetuar Login',
        'Join': 'Associe-se',
        'SkipToGlobalNavigation': 'Pular para navegação global',
        'SkipToContent': 'Pular para conteúdo',
        'SkipToFooter': 'Pular para rodapé',
        'MobileNav': 'Link do menu',
        'CloseMenu': 'Fechar menu',
        'LogInPopoverHeading': 'Possui uma conta AAdvantage®?',
        'LogInPopoverBody': 'Faça login aqui.',
        'TravelAlerts': 'Alertas de viagem'
      }
    };
  }
